import React, { useEffect, useState } from 'react';
import Loadable from '@loadable/component';

import styled from '@emotion/styled';
import { css } from '@emotion/react';
import JSONPretty from 'react-json-pretty';
import { Link } from 'gatsby';
import axios from 'axios';

import ThemeContext from '../../context/ThemeContext';
import Button from '../../components/Button';
import ButtonOutline from '../../components/ButtonOutline';
import ButtonText from '../../components/ButtonText';
import RouterTracker from '../../components/RouterTracker';
import PlayerContext from '../../context/PlayerContext';
import Moment from 'react-moment';

var tinycolor = require('tinycolor2');

const ArchiveList = styled.table(
  (props) => `
  width: 100%;
  margin-bottom: ${props.theme.size.base * 4}px;
  .archive-image {
    display: block;
    border-radius: ${props.theme.size.base * 0.5}px;
    width: ${props.theme.size.base * 9}px;
    height: ${props.theme.size.base * 9}px;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .archive-time {
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    color: ${props.theme.color.foreground[80]};
    transition: color 450ms ease;
  }
  .archive-title {
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    font-weight: 600;
    color: ${props.theme.color.primary[50]};
    transition: color 450ms ease;
    a:visted,
    a {
      color: ${props.theme.color.primary[50]};
      transition: color 450ms ease;
      &:hover {
        color: ${props.theme.color.primary[65]};
      }
    }
  }
  .archive-djs {
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    color: ${props.theme.color.secondary[50]};
    transition: color 450ms ease;
  }
  .archive-action {
    text-align: right;
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
  }
  `
);

const TestStyles = styled.div(
  (props) => `
  padding: ${props.theme.size.base * 4}px;
  .body {
    position: relative;
    z-index: 500;
    max-width: ${props.theme.size.base * 128}px;
    margin: 0 auto;
  }
  .data {
    background: ${props.theme.color.background[90]};
    margin-bottom: ${props.theme.size.base * 4}px;
  }
`
);

const ColorSwatch = styled.div`
  background: ${(props) => props.color};
  color: ${(props) =>
    tinycolor.mostReadable(props.color, ['white', 'black']).toHexString()};
  transition: background 1200ms ease, color 1200ms ease;
  padding: ${(props) => props.theme.size.base}px;
  min-height: 24px;
  position: relative;
  text-align: right;
  &.foreground {
    background: transparent;
    color: ${(props) => props.color};
    padding: ${(props) => props.theme.size.base}px
      ${(props) => props.theme.size.base}px
      ${(props) => props.theme.size.base - 1}px;
    border-bottom: 1px solid ${(props) => props.color};
  }
`;

const ColorSets = styled.div`
  display: flex;
`;
const ColorSet = styled.div`
  padding: 6px 12px;
  flex-grow: 1;
`;
const ButtonSet = styled.div`
  margin-bottom: 24px;
  Button {
    margin-right: 12px;
  }
`;
const LoadableGlobe = Loadable(() => import('../../components/UserGlobe'));

const Index = (props) => {
  const [likes, setLikes] = useState([]);

  const getLikes = async () => {
    try {
      const _likes = await axios.get(
        `https://kuaa-chat-api-2nftz.ondigitalocean.app`
      );
      setLikes(_likes.data); // set State
    } catch (err) {
      console.error(err.message);
    }
  };

  // Set update loop
  useEffect(() => {
    getLikes();
  }, []);

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <PlayerContext.Consumer>
          {(context) => (
            <>
              <LoadableGlobe theme={theme}></LoadableGlobe>
            </>
          )}
        </PlayerContext.Consumer>
      )}
    </ThemeContext.Consumer>
  );
};

export default Index;
